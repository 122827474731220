import styled from "styled-components";
import * as Icon from 'react-feather';
import { colors } from "../../constants/constants";
const primary = "rgb(12,115,114)";

// 'SVGProps<SVGSVGElement>'
const PhoneIcon = () => {
    return (
        // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"/></svg>
    )
}

const icons = {
    facebook: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 320 512\">\n  <path d=\"M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z\"/>\n</svg>",
    github : "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-github\"><path d=\"M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 00-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0020 4.77 5.07 5.07 0 0019.91 1S18.73.65 16 2.48a13.38 13.38 0 00-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 005 4.77a5.44 5.44 0 00-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 009 18.13V22\"/></svg>",
    linkedin: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-linkedin\"><path d=\"M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z\"/><circle cx=\"4\" cy=\"4\" r=\"2\"/></svg>",
    instagram: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 448 512\">\n  <path d=\"M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z\"/>\n</svg>",
    twitter: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 512 512\">\n  <path d=\"M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z\"/>\n</svg>",
    youtube: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 576 512\">\n  <path d=\"M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z\"/>\n</svg>",
    phone : "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-phone\"><path d=\"M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z\"/></svg>",
    mail : "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 32 32\">\n  <path d=\"M24.482 13.633l-2.935 2.543 2.763 2.763a1.5 1.5 0 11-2.121 2.122l-2.914-2.914-2.293 1.987a1.492 1.492 0 01-1.966-.001l.002.001-2.293-1.987-2.914 2.914a1.492 1.492 0 01-2.12 0c-.271-.271-.439-.646-.439-1.061s.168-.789.439-1.061l2.763-2.763-2.935-2.543A1.498 1.498 0 018.501 11c.377 0 .721.139.984.368l-.002-.002 6.518 5.649 6.517-5.649a1.5 1.5 0 011.967 2.265l-.002.002zM27 23c0 .542-.458 1-1 1H6c-.542 0-1-.458-1-1V9c0-.542.458-1 1-1h20c.542 0 1 .458 1 1v14zM26 5H6C3.799 5 2 6.8 2 9v14c0 2.2 1.799 4 4 4h20c2.2 0 4-1.8 4-4V9c0-2.2-1.8-4-4-4z\"/>\n</svg>",
    location: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 32 32\">\n  <path d=\"M16.5 10.568a4 4 0 000 8 4 4 0 000-8m0 3c.551 0 1 .45 1 1s-.449 1-1 1-1-.449-1-1c0-.55.449-1 1-1M16.5 3C10.149 3 5 8.15 5 14.5c0 8.363 11.5 14.636 11.5 14.636S28 22.863 28 14.5C28 8.15 22.851 3 16.5 3m0 3c4.687 0 8.5 3.813 8.5 8.5 0 4.592-5.253 9.003-8.5 11.131-3.249-2.13-8.5-6.54-8.5-11.13C8 9.812 11.813 6 16.5 6\"/>\n</svg>",
    arrow: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 32 32\"><path d=\"M15.7 21.82l-8.26-8.26a1.5 1.5 0 112.12-2.12l.001.001 6.14 6.138 6.14-6.139a1.5 1.5 0 112.122 2.121l-8.26 8.26z\"/></svg>",
    send: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-send\"><path d=\"M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z\"/></svg>",
    up : "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-chevrons-up\"><path d=\"M17 11l-5-5-5 5M17 18l-5-5-5 5\"/></svg>"
};

const socials = [
{
    icon: icons.phone,
    link: "tel:+32476267924",
    label: "+32 476 26 79 24"
}, {
    icon: icons.mail,
    link: "mailto:jeroen.dewelde@gmail.com",
    label: "Jeroen.Dewelde@gmail.com"
}, {
    icon: icons.location,
    label: "Guido Gezellelaan 101 bus 2, 9800 Deinze"
}, {
    icon: icons.linkedin,
    link: "https://www.linkedin.com/in/jeroen-dewelde/",
    label: "/jeroen-dewelde"
}
];

const Row = styled.div`


// padding: 0 3rem;
padding: 0 30px;
// margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    // justify-content: flex-end;

    
    


    position: relative;

    // border: solid 2px red;
    // border: solid 1Px orange;

    &::after {
        content: "";
        display: block;
        width: 60px;
        height: 4px;
        background-color: ${colors.primary};
        position: absolute;
        top: 30px;
        right: 0;
        // transform: translateY(-50%);
        
    }
`;

const IconBox = styled.div`
// transform: translateY(30px);
z-index: 1;
// border: solid 2px blue;

display: flex;
flex-direction: row-reverse;
// background-color: white;
align-items: flex-start;
padding: 0 15px;
// padding-bottom: 30px;

// border: solid 1px purple;
margin-left: auto;
`;

const IconContainer = styled.div`
width: 60px;
height: 60px;
border-radius: 50%;
background-color: ${colors.primary};
display: flex;
justify-content: center;
align-items: center;
`;

const IconText = styled.p`
    font-size: 1.2rem;
    font-weight: 300;
    color: ${colors.primary};
    white-space: pre-line;
    text-align: center;
    padding-right: 30px;
    text-align: center;
    // border: solid 2px red;
`
export interface IconRowTestRightProps {
    position?: "left" | "right";
    items: {
        icon: string;
        link?: string;
        label: string;
    }[]
}

export function IconRowTestRight(props:IconRowTestRightProps) {
    return (
        <Row>
            {props.items.map((item, index) => (
                <IconBox key={index}>
                    <IconContainer>
                        {/* <a href={item.link} target="_blank" rel="noreferrer">
                            <div dangerouslySetInnerHTML={{__html: item.icon}} />
                        </a> */}
                    {/* <Icon.Phone color="white"/> */}
                    {item.icon}
                    </IconContainer>
                    <IconText>{item.label}</IconText>
                </IconBox>
            ))}
            
            </Row>
    );
}