import styled from "styled-components";

import { colors, fonts } from "../../constants/constants";
import { FiLink } from "react-icons/fi";

const Row = styled.div`
  position: relative;
  font-family: ${fonts.secondary.light};

  display: flex;
  flex-direction: column;

  // border: solid 2px red;

  @media screen and (min-width: 48rem) {
    padding-bottom: 30px;
  }

  @media print, screen and (min-width: 70rem) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    padding: 0 30px;
  }

  &::after {
    @media print, screen and (min-width: 70rem) {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background-color: ${colors.primary};
      position: absolute;
      top: 25px;
      left: 0;
    }
  }

  @media print {
    grid-template-columns: repeat(4, 25%);
    padding: 0;
  }
`;

const IconBox = styled.a.attrs((props: { align: "left" | "right" }) => props)`
  z-index: 1;

  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  // padding: 0 30px 30px 45px;
  padding: 15px 30px 15px 15px;
  // border: solid 2Px green;

  text-decoration: none;

  p,
  div,
  &::after {
    transition: all 0.3s ease-in-out;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    // width: 60px;
    width: 15px;
    height: 4px;
    background-color: ${colors.primary};
    // background-color: red;
    // top: 30px;
    top: 40px;
    // transform: translateY(-50%);
    left: 0;
  }

  @media print, screen and (min-width: 30rem) {
    &::after {
      top: 50%;
    }
  }

  @media print, screen and (min-width: 48rem) {
    // padding-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    ${(props) =>
      props.align === "right" &&
      `
    flex-direction: row-reverse;
    padding-right: 15px;

    &::after {
        right: 0;
        left: auto;
        // width: px;
      }
    `}
  }

  //! Same for print
  @media print, screen and (min-width: 70rem) {
    padding: 0 15px;

    flex-direction: column;
    align-items: center;

    &::after {
      display: none;
    }
  }

  ${(props) =>
    props.href &&
    `
  &:hover {
    &::after {
      background-color: ${colors.primaryDark};
    }
  
    p {
      color: ${colors.primaryDark};
    }
  
    div {
      background-color: ${colors.primaryDark};
    }
  }
  `}// border: solid 2px green;
`;

const IconContainer = styled.div`
  width: 50px;
  height: 50px;
  // smaller: 50
  border-radius: 50%;
  background-color: ${colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
`;

const IconText = styled.p.attrs((props: { align: "left" | "right" }) => props)`
  font-size: 1.2rem;
  font-weight: 300;
  color: ${colors.primary};
  white-space: pre-line;
  text-align: center;
  // padding-left: 30px;
  padding-left: 15px;

  ${(props) =>
    props.align === "right" &&
    `
  @media (min-width: 48rem) {
    padding-left: 0;
    padding-right: 30px;
  }
    `}

  @media (min-width: 48rem) {
  }

  //! Same for print
  @media print, screen and (min-width: 70rem) {
    padding: 0;
  }

  @media print {
    font-size: 10pt;
  }
`;

const Icon = styled.div`
  position: absolute;

  display: none;
  // border: solid 2px red;

  // top: calc(100% + 1rem);
  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%);

  @media print {
    display: block;
  }
`;

export interface IconRowProps {
  items: {
    icon: string;
    link?: string;
    label: string;
    external?: boolean;
  }[];
}

export function IconRow(props: IconRowProps) {
  return (
    <Row>
      {props.items.map((item, index) => {
        // check if index is even or odd
        const isEven = index % 2 === 0;

        return (
          <IconBox
            key={index}
            href={item.link}
            target={item.external ? "_blank" : undefined}
            align={isEven ? "left" : "right"}
          >
            <IconContainer>{item.icon}
            {item.link && 
            <Icon>
              <FiLink color="E5E5E5" />
            </Icon>
            }
            </IconContainer>
            <IconText align={isEven ? "left" : "right"}>{item.label}</IconText>
          </IconBox>
        );
      })}
    </Row>
  );
}
