import React from "react";
import styled from "styled-components";
import { colors } from "../../constants/constants";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
//   border: solid 1px red;
`;

const Title = styled.p`
  text-align: left;
  margin-right: 1rem;
  margin: .5rem 0;

  @media print {
    font-size: 12pt;
    margin: 6pt 0;
  }
`;

const Circle = styled.div.attrs((props: { disabled: boolean }) => props)`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${(props) =>
    !props.disabled ? colors.primary : colors.disabled};
  margin-right: 8px;

  @media print {
    width: 12pt;
    height: 12pt;
  }
`;

const CircleDisabled = styled.div.attrs(
  (props: { disabled: boolean }) => props
)`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #e5e5e5;
  margin-right: 8px;
`;

function RatingRow({ amount }: { amount: number }) {
  let row = [];
  let rest = 5 - amount;
  // for( let i = 0; i<amount; i++) {
  for (let i = 0; i < 5; i++) {
    if (i < amount) {
      row.push(React.createElement(Circle, { key: i, disabled: false }));
    } else {
      row.push(React.createElement(Circle, { key: i, disabled: true }));
    }
  }

  return <Row>{row}</Row>;
}

export function Rating({ title, rating }: { title: string; rating: number }) {
  return (
    <Row>
      <Title>{title}</Title>
      <RatingRow amount={rating} />
    </Row>
  );
}
