import styled from "styled-components";
import { colors, fonts } from "../../constants/constants";

const Box = styled.div.attrs((props: {noMarginBottom: boolean, hideOnPrint: boolean, hideOnScreen: boolean}) => props)`
  padding-bottom: 30px;
  // border: solid 2Px green;
  display: ${(props) => props.hideOnScreen ? "none" : "block"};
  
  @media print {
    padding-bottom: ${(props) => props.noMarginBottom ? "0" : "15px"};
    display: ${(props) => props.hideOnPrint ? "none" : "block"};
  }
`;

const Title = styled.h1`
  color: ${colors.primary};
  text-align: left;
  font-family: ${fonts.primary.regular};
  font-size: 2rem;
  margin-bottom: 1rem;
  line-height: 1;
  font-weight: 400;
  margin-top: 0;
  // border: solid 1px orange;

  @media print {
    margin-bottom: 0;
    margin-bottom: 10px;
  }
`;

const Content = styled.p`
  text-align: left;
  font-family: ${fonts.primary.regular};
  font-size: 1rem;
  line-height: 1.5;
  color: ${colors.grey};
  margin-bottom: 0;
  // border: solid 1Px blue;

  @media print {
    margin-top: 12pt;
    font-size: 12pt;
    margin-bottom: 8pt;
    line-height: 1.2;
    color: #000;
  }
`;

export function ContentBox({
  title,
  content,
  children,
  noMarginBottom,
  hideOnPrint,
  hideOnScreen
}: {
  title: string;
  content?: string;
  children?: any;
  noMarginBottom?: boolean;
  hideOnPrint?: boolean;
  hideOnScreen?: boolean
}) {
  return (
    <Box noMarginBottom={noMarginBottom} hideOnPrint={hideOnPrint} hideOnScreen={hideOnScreen}>
      <Title>{title}</Title>
      <Content>
        {content}
        {children && children}
      </Content>
    </Box>
  );
}
