import styled from "styled-components";
import * as Icon from 'react-feather';
import { colors, fonts } from "../../constants/constants";
const primary = "rgb(12,115,114)";

const Grid = styled.div.attrs((props: { wider: boolean}) => props )`
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
    // border: solid 1px orange;
    @media print {
        margin-bottom: .5rem;
        // bij 2de 
        grid-template-columns: ${props => props.wider ? "85px 1fr" : "50px 1fr"};
    }
`;

const Date = styled.p`
line-height: 1.5;
// border: solid 2Px orange;
margin-top: 0;
color: ${colors.grey};
font-family: ${fonts.primary.light};

@media print {
    font-size: 12pt;
    color: #000;
}
`;

const Content = styled.div`
// border: solid 2px red;

ul {
    color: ${colors.grey};
    // border: solid 2px red;
    margin-top: 0;
    padding-left: 1.5rem;
}
`;

const Title = styled.a`
margin-top: 0;
// font-family: 'Nunito', sans-serif;
    // font-weight: 500;
    font-family: ${fonts.third.medium};
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    display: block;
    color: ${colors.grey};
    text-decoration: none;
    transiton: all 0.3s ease-in-out;
    // border: solid 2Px red;
    display: inline-block;

    svg {
        margin-left: .5rem;
    }
    &:hover {
        color: ${colors.primary};
    }

    @media print {
        font-size: 16pt;
        margin-bottom: 4pt;
        color: #000;

        svg {
            display: none;
        }
    }
`;

const Subtitle = styled.a`
font-family: 'Montserrat', sans-serif;
display: flex;
flex-direction: row;
color: ${colors.grey};

// border: solid 1Px orange;

text-decoration: none;
color: #000;



line-height: 1;
@media print {
    color: #000;
}


p {
    // border: solid 2px red;
    margin-top: 0;

    @media print {
        font-size: 12pt;
    }
}

svg {
    @media print {
    display: none;
    // border: solid 2px red;
    }
}

// border: solid 3px orange;

`;

// listitem

export interface TimeLineProps {
    date: string;
    title: string;
    subtitle: string;
    children?: any;
    link?: string;
    wider?: boolean;
}

export function TimeLine({date, title, subtitle, link, wider, children}: TimeLineProps) {
    return (
        
        <Grid wider={wider}>
            <Date>
                {date}
            </Date>
            <Content>
                <Title href={link}>
                    {title}
                    { link && <Icon.Link size="1rem"/>}
                </Title>
                <Subtitle>
                <Icon.MapPin height="1rem" color={colors.primaryLight}/>
                    <p>
                        {subtitle}
                        </p>
                </Subtitle>
                {children && children}
            </Content>
        </Grid>
        
        
    );
}