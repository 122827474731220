const colors = {
    primary: 'rgb(12,115,114)',
    primaryLight: 'rgb(12,115,114, 0.5)',
    primaryDark: 'rgb(6, 53, 53)',
    secondary: '#ffffff',
    grey: '#333333',
    greyLight: '#E5E5E5',
    
    disabled: '#E5E5E5',
};

const fontsOr = {
    primary: 'Montserrat ,sans-serif',
    secondary: 'Nunito, sans-serif',
}

const fonts = {
    primary: {
        extraLight: 'montserrat-extralight ,sans-serif',
        extraLightItalic: 'montserrat-extralightitalic ,sans-serif',
        light: 'montserrat-light ,sans-serif',
        lightItalic: 'montserrat-lightitalic ,sans-serif',
        regular: 'montserrat-regular ,sans-serif',
        regularItalic: 'montserrat-regularitalic ,sans-serif',
        medium: 'montserrat-medium ,sans-serif',
        semiBold: 'montserrat-semibold ,sans-serif',
        semiBoldItalic: 'montserrat-semibolditalic ,sans-serif',
        bold: 'montserrat-bold ,sans-serif',
        boldItalic: 'montserrat-bolditalic ,sans-serif',
        extraBold: 'montserrat-extrabold ,sans-serif',
        extraBoldItalic: 'montserrat-extrabolditalic ,sans-serif',
        black: 'montserrat-black ,sans-serif',
        blackItalic: 'montserrat-blackitalic ,sans-serif',
    }, 
    secondary: {
        extraLight: 'nunito-extralight ,sans-serif',
        extraLightItalic: 'nunito-extralightitalic ,sans-serif',
        light: 'nunito-light ,sans-serif',
        lightItalic: 'nunito-lightitalic ,sans-serif',
        regular: 'nunito-regular ,sans-serif',
        regularItalic: 'nunito-regularitalic ,sans-serif',
        medium: 'nunito-medium ,sans-serif',
        semiBold: 'nunito-semibold ,sans-serif',
        semiBoldItalic: 'nunito-semibolditalic ,sans-serif',
        bold: 'nunito-bold ,sans-serif',
        boldItalic: 'nunito-bolditalic ,sans-serif',
        extraBold: 'nunito-extrabold ,sans-serif',
        extraBoldItalic: 'nunito-extrabolditalic ,sans-serif',
        black: 'nunito-black ,sans-serif',
        blackItalic: 'nunito-blackitalic ,sans-serif',
    },
    third: {
        extraLight: 'montserratalternates-extralight ,sans-serif',
        extraLightItalic: 'montserratalternates-extralightitalic ,sans-serif',
        light: 'montserratalternates-light ,sans-serif',
        lightItalic: 'montserratalternates-lightitalic ,sans-serif',
        regular: 'montserratalternates-regular ,sans-serif',
        regularItalic: 'montserratalternates-regularitalic ,sans-serif',
        medium: 'montserratalternates-medium ,sans-serif',
        semiBold: 'montserratalternates-semibold ,sans-serif',
        semiBoldItalic: 'montserratalternates-semibolditalic ,sans-serif',
        bold: 'montserratalternates-bold ,sans-serif',
        boldItalic: 'montserratalternates-bolditalic ,sans-serif',
        extraBold: 'montserratalternates-extrabold ,sans-serif',
        extraBoldItalic: 'montserratalternates-extrabolditalic ,sans-serif',
        black: 'montserratalternates-black ,sans-serif',
        blackItalic: 'montserratalternates-blackitalic ,sans-serif',
    }
}

export { colors, fonts };
