import { FiLink } from "react-icons/fi";
import styled from "styled-components";
import { colors, fonts } from "../../constants/constants";

const HeaderRow = styled.div.attrs((props: { hide: boolean }) => props)`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
  justify-content: space-between;
  align-items center;
  padding: 30px;
  //! print
  // padding: 15px;
  
  //! Same for print
  @media (min-width: 48rem) {
    flex-direction: row;
  }

  //! Same for print
  @media print {
    display: flex;
    flex-direction: row;

    padding: 15px;
  }
`;

const HeaderImage = styled.a`
  width: 120px;
  height: 120px;

  position: relative;

  flex-shrink: 0;
  border: solid 4px ${colors.primary};
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: ${colors.primary};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 16px;
    border-radius: 50%;
  }

  @media print {
    width: 100px;
    height: 100px;
  }
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  //! same for print
  @media (min-width: 48rem) {
    height: 120px;
    margin-right: 3rem;
  }

  //! same for print
  @media print {
    height: 120px;
    margin-right: 3rem;
  }
`;

const HeaderTitle = styled.h1`
  position: relative;
  height: 50%;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;

  text-transform: uppercase;
  text-align: center;

  font-family: ${fonts.secondary.regular};
  font-size: 32px;
  color: ${colors.primary};

  //! same for print
  @media (min-width: 48rem) {
    border-bottom: solid 4px ${colors.primary};
    padding-right: 2rem;
    text-align: right;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%) translateY(2px);
      border-radius: 50%;

      width: 12px;
      height: 12px;
      background-color: ${colors.primary};
    }
  }

  //! same for print
  @media print {
    border-bottom: solid 4px ${colors.primary};
    padding-right: 2rem;
    text-align: right;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%) translateY(2px);
      border-radius: 50%;

      width: 12px;
      height: 12px;
      background-color: ${colors.primary};
    }
  }
`;

const HeaderSubtitle = styled.p`
  height: 50%;

  margin-top: 0;
  padding-top: 8px;
  margin-bottom: 0;

  text-align: center;
  color: ${colors.primary};
  text-transform: uppercase;
  font-family: ${fonts.secondary.light};
  font-size: 18px;

  //! same for print
  @media (min-width: 48rem) {
    padding-right: 2rem;

    text-align: right;
  }

  //! same for print
  @media print {
    padding-right: 2rem;
    text-align: right;
  }
`;

const Icon = styled.div`
  position: absolute;

  display: none;

  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%);

  @media print {
    display: block;
  }
`;

export interface HeaderProps {
  title: string;
  subtitle: string;
  imageUrl: string;
  secondPage?: boolean;
}

export function Header(props: HeaderProps) {
  return (
    <HeaderRow hide={props.secondPage}>
      <HeaderImage
        href="http://www.jeroendewelde.be"
        title="bezoek mijn portfolio"
        target="_blank"
      >
        <img src={props.imageUrl} alt={props.title} />

        <Icon>
          <FiLink color="E5E5E5" />
        </Icon>
      </HeaderImage>
      <HeaderText>
        <HeaderTitle>{props.title}</HeaderTitle>
        <HeaderSubtitle>{props.subtitle}</HeaderSubtitle>
      </HeaderText>
    </HeaderRow>
  );
}
