import * as Icon from "react-feather";
import { BiCodeAlt, BiCoffeeTogo } from "react-icons/bi";
import { FaHeadphones } from "react-icons/fa";
import { GiCommercialAirplane, GiSofa } from "react-icons/gi";
import { MdBakeryDining, MdLaptopMac } from "react-icons/md";


// import profileImage from "./images/Author_Memoji_Cropped.png";
import { dataType } from "../../../types/dataType";
// import profileImage from "./assets/images/profile--square2.png";
import profileImage from '../../images/profile--square2.png';
// import profileImage from "./assets/images/profile--square.png";

const dataEN: dataType = {
    name: "Jeroen Dewelde",
    function: "Front-End Developer",
    profilePictureUrl: profileImage,
    // profilePictureUrl: "https://www.fillmurray.com/640/360",
    intro:
      "I am a very motivated person, who can finish a project independently or in a group. I find the group feeling very important and can therefore be a motivator for a colleague to get back the desire to continue to the finish.",
    socials: [
      {
        icon: <Icon.Phone color="white" />,
        label: "+32 476 26 79 24",
        link: "tel:+32476267924",
      },
      {
        icon: <Icon.Mail color="white" />,
        label: "Jeroen.Dewelde@gmail.com",
        link: "mailto:Jeroen.Dewelde@gmail.com",
      },
      {
        icon: <Icon.Home color="white" />,
        label: "9800 Deinze",
      },
      {
        icon: <Icon.Linkedin color="white" />,
        label: "/jeroen-dewelde-162533134",
        link: "https://www.linkedin.com/in/jeroen-dewelde-162533134/",
        external: true,
      },
    ],
    education: [
      {
        name: "Associate Degree in Programming",
        location: "Artevelde University of Applied Science - Mariakerke",
        extra: `<ul>
        <li>
        Graduated magna cum laude
        </li>
        <li>
        Received award of "The Ambassador" for our year
        </li>
        <li>
        Was 2 years active as student representative & 1 year president of the student council
        </li>
        </ul>`,
        date: "2020 - 2022",
        link: "https://www.arteveldehogeschool.be/opleidingen/graduaat/programmeren",
      },
      {
        name: "New Media & Communicatie Technologie @Home",
        location: "Howest - Kortrijk",
        extra: '<ul><li>Did not fully complete this degree due to working full-time</li></ul>',
        date: "2013 - 2018",
        link: "https://mct.be/programma/",
      },
      {
        name: "Elektriciteit Elektronica",
        location: "Scheppers Instituut - Wetteren",
        date: "2007 - 2011",
        link: "https://www.scheppers-wetteren.be/index.php/studieaanbod/technisch-onderwijs-huidige-structuur/1685-elektriciteit-elektronica-2",
      },
    ],
    workExperience: [
      {
        name: "Student Front-End Developer",
        location: "Duke & Grace - Gent",
        date: "feb 2022 - may 2022",
        tasks: [
          "Group-project displaying dynamic info about the company & employees. Input comming from Slack, saved in Strapi + NestJS back-office & displayed in d3.js Front-End",
          "Disconnect 3-piece website from Drupal & connect it to Strapi",
          "Convert data coming from the CMS inside Nx to the Front-end",
          "Create, optimize & refactor visual components in NextJS coming form the Strapi data",
        ],
        link: "https://www.dukeandgrace.com/en/",
      },
      {
        name: "Student Front-End Developer",
        location: "Open Summer of Code - remote session",
        date: "jul 2021",
        tasks: [
          "Team research concerning diversity & inclusion in the IT-sector. Improving their current selection for the upcoming edition(s)",
          "Improvement of their current UI tool",
          "Connect with new database & add exra filters, improve data structure",
        ],
        link: "https://osoc21.github.io/selection-tool/",
      },
      {
        name: "Quality Inspector",
        location: "Helios Winsol - Aalter",
        date: "jul 2020 - sept 2020",
        tasks: [
          "Technical inspection of their luxurious assortment",
          "Check & maintain completion of all orders",
          "Assist in production",
        ],
        link: "https://winsol.eu/en/inspiration/pergola-so-with-glass-sliding-walls-screens",
      },
      {
        name: "Intern Web Development",
        location: "Influo - Gent",
        date: "dec 2019",
        tasks: [
          "First experience in the IT-sector",
          "Create the code for their website, coming from Adobe Xd",
          "Modify SASS for their e-mail templates",
        ],
        link: "https://www.influo.com/",
      },
      {
        name: "Delivery driver B & all-round",
        location: "Levi Party Rental - Merelbeke",
        date: "2012 - 2020",
        tasks: [
          "Delivery of party equipment for big events & home orders",
          "Making clear agreements with customers at the location",
          "Building up & taking down party equipment for big events",
          "Assist at other departments when needed",
        ],
        link: "https://www.levipartyrental.be/",
      },
    ],
    technicalSkills: [
      {
        name: "HTML/SCSS",
        rating: 5,
      },
      {
        name: "Typescript",
        rating: 4,
      },
      {
        name: "React/ NextJS",
        rating: 4,
      },
      {
        name: "Nest.JS",
        rating: 4,
      },
      {
        name: "GraphQL",
        rating: 4,
      },
      {
        name: "React Native/ Expo",
        rating: 4,
      },
      {
        name: "PHP & Wordpress",
        rating: 3,
      },
      
    ],
    personalSkills: [
      {
        name: "Go-getter",
        highlight: false,
      },
      {
        name: "Empatic",
        highlight: false,
      },
      {
        name: "Bescheiden",
        highlight: false,
      },
      {
        name: "Understanding",
        highlight: false,
      },
      {
        name: "Organized",
        highlight: false,
      },
      {
        name: "Flexible",
        highlight: true,
      },
      {
        name: "Problem Solver",
        highlight: true,
      },
      {
        name: "Collegially",
        highlight: false,
      },
      {
        name: "Perfectionist",
        highlight: false,
      },
      {
        name: "Eager",
        highlight: false,
      },
      {
        name: "Enthusiastic",
        highlight: true,
      },
      {
        name: "Critical",
        highlight: false,
      },
    ],
    hobbies: [
      {
        name: "Traveling",
        icon: <GiCommercialAirplane />,
      },
      {
        name: "Coffee",
        icon: <BiCoffeeTogo />,
      },
      {
        name: "Music",
        icon: <FaHeadphones />,
      },
      {
        name: "Coding",
        icon: <BiCodeAlt />,
      },
      {
        name: "Baking",
        icon: <MdBakeryDining />,
      },
      {
        name: "Interior",
        icon: <GiSofa />,
      },
      {
        name: "Tech",
        icon: <MdLaptopMac />,
      },
      {
        name: "Design",
        icon: <Icon.Phone />,
      },
    ],
  };

  export default dataEN;