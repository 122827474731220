import styled from "styled-components";
import { colors } from "../../constants/constants";

// const primary = "rgb(12,115,114)";

const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items center;
  padding: 30px;
  
  // border: solid 2px blue;
`;

const HeaderImage = styled.div`
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    border: solid 4px ${colors.primary};
    border-radius: 50%;
    
    
    position: relative;
    // display: block;
    margin: 0 auto;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        // display: block;
        
    }

    &::after {
      // content: '';
      position: absolute;
      top: calc(50% + 4px);
      left: -4px;
      // transform: translateX(-8px) translateY(8px);
      
      // border-radius: 50%;
      
      height: 50%;
      width: 4px;
      // height: 12px;
      background-color: ${colors.primary};
  }
`;

const HeaderText = styled.div`
    display: flex;
    flex-direction: column;
    
    width: 100%;
    // margin-right: 3rem;
    flex-grow: 1;
    height: 120px;
    // border: solid 3px orange;
    // margin-left: 60px;
    // border-left: solid 4px ${colors.primary};
    position: relative;
    text-align: center;
    

    &::after {
      // content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateX(-8px) translateY(8px);
      
      border-radius: 50%;
      
      width: 12px;
      height: 12px;
      background-color: ${colors.primary};
  }
`;

const HeaderTitle = styled.h1`
// height: 50%;
text-transform: uppercase;
// border-bottom: solid 4px ${colors.primary};
text-align: right;
position: relative;
// padding-right: 2rem;
font-family: 'Nunito', sans-serif;
font-size: 36px;
color: ${colors.primary};
margin-bottom: 0;
margin-top: 0;
padding-bottom: 0;
text-align: right;
padding-top: 1rem;




`;

const HeaderSubtitle = styled.p`
height: 50%;
text-align: right;
// padding-right: 2rem;
font-family: 'Montserrat', sans-serif;
color: ${colors.primary};
text-transform: uppercase;
font-family: 'Nunito', sans-serif;
font-size: 18px;

margin-top: 0;
padding-top: 8px;
margin-bottom: 0;
text-align: right;
`;

export interface HeaderTestProps {
  title: string;
    subtitle: string;
    imageUrl: string;
}

export function HeaderTest(props:HeaderTestProps) {
  return (
    <HeaderRow>
      <HeaderImage>
        <img src={props.imageUrl} alt={props.title} />
        </HeaderImage>
      <HeaderText>
        <HeaderTitle>
            {props.title}
        </HeaderTitle>
        <HeaderSubtitle>
            {props.subtitle}
        </HeaderSubtitle>
    </HeaderText>
    </HeaderRow>
  );
}
