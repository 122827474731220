import * as Icon from "react-feather";
import { BiCodeAlt, BiCoffeeTogo } from "react-icons/bi";
import { FaHeadphones } from "react-icons/fa";
import { GiCommercialAirplane, GiSofa } from "react-icons/gi";
import { MdBakeryDining, MdLaptopMac } from "react-icons/md";

// import profileImage from "./images/Author_Memoji_Cropped.png";
import { dataType } from "../../../types/dataType";
// import profileImage from "./assets/images/profile--square2.png";
import profileImage from "../../images/profile--square2.png";
// import profileImage from "./assets/images/profile--square.png";

const dataNL: dataType = {
  name: "Jeroen Dewelde",
  function: "Front-End Developer",
  profilePictureUrl: profileImage,
  // profilePictureUrl: "https://www.fillmurray.com/640/360",
  intro:
    "Ik ben een zeer gedreven persoon, die zelfstandig of in groep een project tot een goed einde kan brengen. Het groepsgevoel vind ik zeer belangrijk en kan daardoor als motivator een collega terug goesting doen krijgen om verder te gaan naar de finish.",
  socials: [
    {
      icon: <Icon.Phone color="white" />,
      label: "+32 476 26 79 24",
      link: "tel:+32476267924",
    },
    {
      icon: <Icon.Mail color="white" />,
      label: "Jeroen.Dewelde@gmail.com",
      link: "mailto:Jeroen.Dewelde@gmail.com",
    },
    {
      icon: <Icon.Home color="white" />,
      label: "9800 Deinze",
    },
    {
      icon: <Icon.Linkedin color="white" />,
      label: "/jeroen-dewelde-162533134",
      link: "https://www.linkedin.com/in/jeroen-dewelde-162533134/",
      external: true,
    },
  ],
  education: [
    {
      name: "Graduaat Programmeren",
      location: "Arteveldehogeschool - Mariakerke",
      extra: `<ul>
        <li>
        Afgestudeerd met grote onderscheiding
        </li>
        <li>
        Prijs ontvangen van "The Ambassador" voor ons jaar
        </li>
        <li>
        2 jaar actief als student verantwoordelijke & 1 jaar voorzitter van de studentenraad
        </li>
        </ul>`,
      date: "2020 - 2022",
      link: "https://www.arteveldehogeschool.be/opleidingen/graduaat/programmeren",
    },
    {
      name: "New Media & Communicatie Technologie @Home",
      location: "Howest - Kortrijk",
      extra:
        "<ul><li>Niet volledig afgemaakt, door het uitvoeren van een full-time job</li></ul>",
      date: "2013 - 2018",
      link: "https://mct.be/programma/",
    },
    {
      name: "Elektriciteit Elektronica",
      location: "Scheppers Instituut - Wetteren",
      date: "2007 - 2011",
      link: "https://www.scheppers-wetteren.be/index.php/studieaanbod/technisch-onderwijs-huidige-structuur/1685-elektriciteit-elektronica-2",
    },
  ],
  workExperience: [
    {
      name: "Student Front-End Developer",
      location: "Duke & Grace - Gent",
      date: "feb 2022 - mei 2022",
      tasks: [
        "Groeps-project die dynamische info over het bedrijf & werknemers voorstelt. Input komend van Slack, opgeslagen in een Strapi + NestJS back-office & voorgesteld in d3.js Front-end",
        "3-delige website loskoppelen van Drupal -> Strapi CMS",
        "Data ommappen van cms naar Front-end in Nx",
        "Visuele componenten uitwerken in NextJS komende uit Strapi",
      ],
      link: "https://www.dukeandgrace.com/en/",
    },
    {
      name: "Student Front-End Developer",
      location: "Open Summer of Code - remote session",
      date: "jul 2021",
      tasks: [
        "Team onderzoek i.v.m. diversiteit & inclussie in de IT-sector & hun eigen selectie verbeteren voor de volgende editie(s)",
        "Huidige UI tool verbeteren",
        "Verbinden met nieuwe database & extra filters/datastructuur uitwerken",
      ],
      link: "https://osoc21.github.io/selection-tool/",
    },
    {
      name: "Kwaliteits Controlleur",
      location: "Helios Winsol - Aalter",
      date: "jul 2020 - sept 2020",
      tasks: [
        "Technische controle volledigheid Luxe veranda's",
        "Volledigheid opvolgen van uitgaande bestellingen",
        "Ondersteuning van de productie",
      ],
      link: "https://winsol.eu/en/inspiration/pergola-so-with-glass-sliding-walls-screens",
    },
    {
      name: "Stagiair Web Development",
      location: "Influo - Gent",
      date: "dec 2019",
      tasks: [
        "Eerste kennismaking van de IT-sector",
        "Adobe Xd ontwerp van hun website omzetten naar Code",
        "SASS aanpassen voor hun mail templates",
      ],
      link: "https://www.influo.com/",
    },
    {
      name: "Chauffeur B & all-round",
      location: "Levi Party Rental - Merelbeke",
      date: "2012 - 2020",
      tasks: [
        "Leveren feest-materiaal bij grote events & particulieren",
        "Duidelijke afspraken maken met klanten op locatie",
        "Bij grote events helpen met opbouwen & afbreken",
        "Assisteren op andere afdelingen wanneer nodig",
      ],
      link: "https://www.levipartyrental.be/",
    },
  ],
  technicalSkills: [
    {
      name: "HTML/SCSS",
      rating: 5,
    },
    {
      name: "Typescript",
      rating: 4,
    },
    {
      name: "React/ NextJS",
      rating: 4,
    },
    {
      name: "Nest.JS",
      rating: 4,
    },
    {
      name: "GraphQL",
      rating: 4,
    },
    {
      name: "React Native/ Expo",
      rating: 4,
    },
    {
      name: "PHP & Wordpress",
      rating: 3,
    },
  ],
  personalSkills: [
    {
      name: "Doorzetter",
      highlight: false,
    },
    {
      name: "Empathisch",
      highlight: false,
    },
    {
      name: "Bescheiden",
      highlight: false,
    },
    {
      name: "Begripvol",
      highlight: false,
    },
    {
      name: "Ordelijk",
      highlight: false,
    },
    {
      name: "Flexibel",
      highlight: true,
    },
    {
      name: "Problem Solver",
      highlight: true,
    },
    {
      name: "Collegiaal",
      highlight: false,
    },
    {
      name: "Perfectionist",
      highlight: false,
    },
    {
      name: "Leergierig",
      highlight: false,
    },
    {
      name: "Enthousiast",
      highlight: true,
    },
    {
      name: "Kritisch",
      highlight: false,
    },
  ],
  hobbies: [
    {
      name: "Reizen",
      icon: <GiCommercialAirplane />,
    },
    {
      name: "Koffie",
      icon: <BiCoffeeTogo />,
    },
    {
      name: "Muziek",
      icon: <FaHeadphones />,
    },
    {
      name: "Coderen",
      icon: <BiCodeAlt />,
    },
    {
      name: "Bakken",
      icon: <MdBakeryDining />,
    },
    {
      name: "Interieur",
      icon: <GiSofa />,
    },
    {
      name: "Tech",
      icon: <MdLaptopMac />,
    },
    {
      name: "Design",
      icon: <Icon.Phone />,
    },
  ],
};

export default dataNL;
