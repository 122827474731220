import styled from "styled-components";
import { colors } from "../../constants/constants";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  @media screen and (min-width: 30rem) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 48rem) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media print {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6pt;
  }

  // border: solid 2px red;
`;

const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  // border: solid 2Px green;
`;

const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: solid 2px ${colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;

  
  
  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${colors.grey};

    @media print {
      width: 12pt;
      height: 12pt;
      color: #000;
    }
  }

  
  @media print {
    width: 30px;
    height: 30px;
    padding: 0;
  }
`;

const IconText = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${colors.grey};
  text-align: center;
  margin-bottom: 1rem;


  @media print {
    font-size: 12pt;
    margin-bottom: 0;
  }
`;

export interface IconGridProps {
  items: {
    name: string;
    icon: string | any;
  }[];
}

export function IconGrid(props: IconGridProps) {
  return (
    <Grid>
      {props.items.map((item, index) => {
        console.log("..item..", item);
        return (
          <IconBox key={index}>
            <IconContainer>{item.icon}</IconContainer>
            <IconText>{item.name}</IconText>
          </IconBox>
        );
      })}
    </Grid>
  );
}
