import * as Icon from "react-feather";
import { BiCodeAlt, BiCoffeeTogo } from "react-icons/bi";
import { FaHeadphones } from "react-icons/fa";
import { GiCommercialAirplane, GiSofa } from "react-icons/gi";
import { FiPrinter } from "react-icons/fi";
import { MdBakeryDining, MdLaptopMac } from "react-icons/md";
import styled from "styled-components";
import "./App.css";
import { Header, IconRow } from "./components";
import { ContentBox } from "./components/content-box/content-box";
import { IconGrid } from "./components/icon-grid/icon-grid";
import { ListItem } from "./components/list-item/list-item";
import { Rating } from "./components/rating/rating";
import { TimeLine } from "./components/time-line/time-line";
import { ListItemsSimple } from "./components/list-items-simple/list-items-simple";
import { colors } from "./constants/constants";
// import profileImage from "./images/Author_Memoji_Cropped.png";
import profileImage from "./assets/images/profile--square2.png";
// import profileImage from "./assets/images/profile--square.png";
import dataEN from "./assets/data/en/data";
import dataNL from "./assets/data/nl/data";





const AppC = styled.div`
  // padding: 1rem;
  background-color: #e5e5e5;

  @media (min-width: 30rem) {
    padding: 1rem;
  }

  @media print {
    padding: 0;
  }
`;

const AppContainer = styled.div`
max-width: 90rem;
position: relative;
margin: 0 auto;
  padding: 2rem;

  // background-color: ${colors.greyLight};
  background-color: white;
  // border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  @media (min-width: 30rem) {
    border-radius: 8px;
  }
  

  @media print {
    box-shadow: none;
    border: none;
    padding: 0;
  }
`;

const Container = styled.div`
  border: solid 4px ${colors.primary};
  min-height: 80vh;

  background-color: white;

  @media print {
    border: none;
  }
`;

const FlexColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
`;

const Body = styled.div`
  display: flex;

  flex-direction: column;
  padding: 30px;

  @media print, screen and (min-width: 48rem) {
    flex-direction: row;
    align-items: stretch;
  }

  @media print {
    padding: 0 15px;
  }
`;

const Left = styled.div`
  flex: 1;
  max-width: 30rem;

  @media print, screen and (min-width: 48rem) {
    padding-right: 30px;
    border-right: solid 2px ${colors.primaryLight};
  }

  @media print {
    max-width: 45%;
    padding-right: 7.5pt;
  }
`;

const Right = styled.div`
  flex: 1;

  @media screen and (min-width: 48rem) {
    padding-left: 30px;
  }
  @media print {
    padding-left: 7.5pt;
  }
`;

const Page = styled.div.attrs((props: { hideOnScreen: boolean }) => props)`
  break-after: page;
  
  // border: solid 4px ${colors.primary};
  box-sizing: border-box;
  display: ${(props) => (props.hideOnScreen ? "none" : "flex")};
  flex-direction: column;
  position: relative;
  
  @media print {
    display: flex;
    height: 100vh;
    max-height: 100vh;
    border: solid 4px ${colors.primary};
    overflow: hidden;
    
  }

  //! print
  // width: 210mm !important;
  // margin: 0 auto;
  // border: solid 4px ${colors.primary};
`;

const PageNumber = styled.div`

// border: solid 1px red;
margin-top: auto;
postion: absolute;
// right: 2.5rem;
// bottom: 2rem;
right: 0;
bottom: 10rem;
// text-align: right;
display: block;
display: none;
// justify-content: flex-end;
// bottom: 2rem;
padding-right: .5rem;
padding: 1rem .5rem;
color: ${colors.primaryLight};

@media print {
  // display: flex;
}
`;

const PrintButton = styled.div`
width: 48px;
height: 48px;
display: none;
align-items: center;
justify-content: center;
border-radius: 50%;
border: solid 2px ${colors.primaryLight};
color: ${colors.primaryLight};
background-color: #FCFCFC;

font-size: 1.5rem;
box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

position: fixed;
z-index: 100;

bottom: 2rem;
right: 4rem;

cursor: pointer;

transition: all 0.2s ease-in-out;
&:hover, &:focus {
  border: solid 2px ${colors.primary};
color: ${colors.primary};
  box-shadow: 0 10px 30px -3px rgb(0 0 0 / 0.4), 0 4px 12px -4px rgb(0 0 0 / 0.4);
}

@media screen and (min-width: 30rem) {
  display: flex;
}

@media screen and (min-width: 98rem) {
  // border: solid 2Px red;
  // right: 1rem;
  bottom: 1rem;
  right: calc(50% - 49rem);
}

@media print {
  display: none;
}
`;

const LangSwitcher = styled.ul`
list-style-type: none;
// border: solid 2Px red;
  position: absolute;
  top: 0;
  right: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0;
  margin-top: .5rem;
  
  text-decoration: none;



  > li {
    a {
      
      text-decoration: none;
    }
    a, a:link, a:visited {
      color: ${colors.primaryLight};

      &.active {
        color: ${colors.primary};
      }
    }
  }

  @media print {
    display: none;
  }
`;

function App() {
const lang = window.location.pathname.split('/')[1];
let data = dataNL;

if(lang === 'en') {
  data = dataEN;
}
// const data = lang == 'en' ? dataEN : dataNL;


  return (
    <>
      <AppC>
        
<PrintButton onClick={() => window.print()} title="print or save to PDF">
<FiPrinter/>
</PrintButton>
        <AppContainer>
        <LangSwitcher>
          <li>
            <a href="/nl" className={lang !== 'en' ? 'active' : ''}>NL</a>
          </li>
          <li>
            <a href="/en" className={lang === 'en' ? 'active' : ''}>EN</a>
          </li>
        </LangSwitcher>
          <Container>
            <Page>
              <Header
                title={data.name}
                subtitle={data.function}
                imageUrl={data.profilePictureUrl}
              />
              {/* <div onClick={() => window.print()}>
print
              </div> */}
              <IconRow items={data.socials} />

              <Body>
                <Left>
                  <ContentBox title="Bio" content={data.intro} />
                  <ContentBox title={lang === 'en' ? 'Technical Skills' : 'Technisch Skills'} noMarginBottom={true}>
                    {data.technicalSkills?.map((skill, index) => (
                      <Rating
                        key={index}
                        title={skill.name}
                        rating={skill.rating}
                      />
                    ))}
                  </ContentBox>
                  <ContentBox title={lang === 'en' ? 'Personal Skills' : 'Persoonlijke Skills'} noMarginBottom={true}>
                    <FlexColumn>
                      {data.personalSkills?.map((skill, index) => (
                        <ListItem key={index} name={skill.name} highlight={skill.highlight}/>
                      ))}
                    </FlexColumn>
                  </ContentBox>
                  <ContentBox title={lang === 'en' ? 'Hobbies & Interests' : 'Hobbies & Interesses'} hideOnPrint={true}>
                    <IconGrid items={data.hobbies} />
                  </ContentBox>
                  
                </Left>
                <Right>
                  <ContentBox title={lang === 'en' ? 'Education' : 'Opleiding'}>
                    {data.education?.map((education, index) => (
                      <TimeLine
                        date={education.date}
                        title={education.name}
                        subtitle={education.location}
                        link={education?.link}
                      >
                        {/* <p> */}
                        {education?.extra && (
                          <div
                          dangerouslySetInnerHTML={{ __html: education?.extra }}></div>

                        
                        )}
                        
                      </TimeLine>
                    ))}
                  </ContentBox>
                  <ContentBox title={lang === 'en' ? 'Work Experience' : 'Werkervaring'} hideOnPrint={true}>
                    {data.workExperience?.map((workExperience, index) => (
                      <TimeLine
                        date={workExperience.date}
                        title={workExperience.name}
                        subtitle={workExperience.location}
                        link={workExperience?.link}
                      >
                           <ListItemsSimple items={workExperience.tasks} />
                      </TimeLine>
                    ))}
                  </ContentBox>
                  <ContentBox title={lang === 'en' ? 'Hobbies & Intrests' : 'Hobbies & Interesses'} hideOnScreen={true}>
                    <IconGrid items={data.hobbies} />
                  </ContentBox>
                  
                  
                </Right>
              </Body>
              <PageNumber>1 / 2</PageNumber>
            </Page>
            
            <Page hideOnScreen={true}>
            <Header
                title={data.name}
                subtitle={data.function}
                imageUrl={data.profilePictureUrl}
                secondPage={true}
              />
              <Body>
                {/* <Left>
                  <ContentBox title="Hobbies & Interesses">
                    <IconGrid items={data.hobbies} />
                  </ContentBox>
                </Left> */}
                {/* <Right> */}
                  <ContentBox title={lang === 'en' ? 'Work Experience' : 'Werkervaring'} hideOnScreen={true}>
                    {data.workExperience?.map((workExperience, index) => (
                      <TimeLine
                        date={workExperience.date}
                        title={workExperience.name}
                        subtitle={workExperience.location}
                        link={workExperience?.link}
                        wider={true}
                      >
                        {
                          <ListItemsSimple items={workExperience.tasks} />

                        }
                      </TimeLine>
                    ))}
                  </ContentBox>
                {/* </Right> */}
              </Body>
                <PageNumber>2 / 2</PageNumber>
            </Page>

            {/* <Page>
              <p>
                test
              </p>
            </Page> */}
          </Container>
        </AppContainer>
      </AppC>
    </>
  );
}

export default App;
