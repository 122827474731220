interface ListItemsSimpleProps {
    items: string[];
}

export function ListItemsSimple({items}: ListItemsSimpleProps) {
  return (
    <ul>
      {items.map((item, index) => {
        return (
          <li key={index}>
            {item}
          </li>
        );
      })}
    </ul>
  );
}