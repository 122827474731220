import styled from "styled-components";
import { colors, fonts } from "../../constants/constants";

const Item = styled.li`
  list-style-type: none;
  margin-left: 20px;
  position: relative;
  margin-bottom: 1rem;
  font-family: ${fonts.primary.regular};
  color: ${colors.grey};

  // border: solid 2px purple;
  

  &::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${colors.primary};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    left: -20px;

    @media print {
      // width: 1Opt;
      // height: 10pt;

    }
  }

  @media print {
    font-size: 12pt;
    margin-bottom: 6pt;
    color: #000;
  }
`;

export interface ListItemProps {
  name: string;
  highlight?: boolean;
}

export function ListItem(props: ListItemProps) {
  return (
    <>
      <Item>
        {props.highlight ? <strong>{props.name}</strong> : props.name}
        </Item>
    </>
  );
}
